/*-----------------------------------------------------------------------------------

    Template Name: Your template name 
    Template URI: http://bootexperts.com
    Description: This is html5 template
    Author: BootExperts
    Author URI: http://bootexperts.com
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    1. Theme Default CSS (body, link color, section etc)

-----------------------------------------------------------------------------------*/

/*----------------------------------------*/
/*  1.  Theme default CSS
/*----------------------------------------*/

html, body {height: 100%; font-family: 'Roboto', sans-serif;font-weight:400;background:#152036;}
.floatleft {float:left}
.floatright {float:right}
.alignleft {float:left;margin-right:15px;margin-bottom: 15px}
.alignright {float:right;margin-left:15px;margin-bottom: 15px}
.aligncenter {display:block;margin:0 auto 15px}
a:focus {outline:0px solid}
img {max-width:100%;height:auto}
.fix {overflow:hidden}
p {margin:0 0 15px;}
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 10px;
  font-weight:700;
}
a {transition: all 0.3s ease 0s;text-decoration:none;}
a:hover{
  color: #ec4445;
  text-decoration: none;
}
a:visited, a:link, a:active {
  text-decoration: none;
}
a:active, a:hover {
  outline: 0 none;
}
ul{
list-style: outside none none;
margin: 0;
padding: 0
}
.clear{clear:both}
body {}

.mg-tb-30{
	margin:30px 0px;
}
.mg-b-30{
	margin-bottom:30px;
}
.mg-tb-15{
	margin:15px 0px;
}
.mg-t-30{
	margin-top:30px;
}
.mg-b-10{
	margin-bottom:16px;
}
.mg-t-15{
	margin-top:15px;
}
.mg-b-15{
	margin-bottom:15px;
}
.mt-b-30{
	margin-bottom:30px;
}
/* @font-face {
  font-family: 'nalika';
  src:
    url('fonts/nalika.ttf?nukgc0') format('truetype'),
    url('fonts/nalika.woff?nukgc0') format('woff'),
    url('fonts/nalika.svg?nukgc0#nalika') format('svg');
  font-weight: normal;
  font-style: normal;
} */

/*----------------------------------------*/
/*  8. Menu collapse Area
/*----------------------------------------*/
.all-content-wrapper {
    margin-left: 205px;
    transition: all 0.3s;
}
.left-sidebar-pro {
    z-index: 9999;
    width: 240px;
}
#sidebar {
    min-width: 205px;
    background: #1b2a47;
    color: #fff;
    position: fixed;
    transition: all 0.3s;
    z-index: 999;
    height: 100%;
    overflow-y: scroll;
    padding: 20px 0;
    text-align: left;
}
#sidebar::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#sidebar::-webkit-scrollbar {
	width: 4px;
	background-color: #F5F5F5;
}

#sidebar::-webkit-scrollbar-thumb {
	background-color: #fff;
}
.sidebar-nav {background: none !important;}
.sidebar-nav .site-logo {
    width: 180px;
    padding: 0 15px 15px;
}
.mini-navbar .all-content-wrapper {
    margin-left: 80px;
    transition: all 0.3s;
}
#sidebar.active {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
}
#sidebar.active .sidebar-header h3, #sidebar.active .CTAs {
    display:none;
}

#sidebar.active .sidebar-header strong {
    display: block;
}

#sidebar.active ul li .sidebar-right-icon {
    display:none;
}
.bar-button-pro {
    margin: 13px 0px;
}
#sidebar .sidebar-header {
    padding: 1px 0px;
	position: relative;
    z-index: 99999;
    background: #1b2a47;
}
#sidebar .sidebar-header strong {
    display: none;
    font-size: 1.8em;
}
.nalika-profile {
    padding: 20px;
	position: relative;
    z-index: 99999;
    background: #1b2a47;
}
.profile-dtl {
    text-align: center;
}
.profile-dtl img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px solid #152036;
}
.profile-dtl h2{
	font-size:16px;
	color:#fff;
	font-weight:400;
	margin: 10px 0px 15px 0px;
}
ul.dtl-social{
	display: flex;
    justify-content: center;
    justify-content: flex-end;
}
ul.dtl-social li{
    margin-top: 17px !important;
    
	padding: 0px 15px;
    border-left: 1px solid #152036;
}
ul.dtl-social li:first-child{
    border-left: 0px solid #152036;
}
ul.dtl-social li a{
	color: #fff;
    font-size: 20px;
  
}

.sidebar-header {
    text-align: center;
}
.sidebar-header h3 {
    font-size: 20px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 5px;
}
#sidebar.active .sidebar-header .main-logo{
	display:none;
}
#sidebar.active
#sidebar.active .sidebar-header h3{
	display:none;
}
#sidebar.active .sidebar-header p{
	display:none;
}
#sidebar ul li a .sidebar-right-icon{
	float: right;
}
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro{
	width:100%;
}
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li{
	display:block;
	float:none;
	position:relative;
}
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li .left-menu-dropdown{
	left:0px;
	top:0px;
	opacity:0;
	border-radius:0;
	border:0px solid #fff;
	box-shadow:none;
	background:#ebebeb;
}
.left-custom-menu-adp-wrap .dropdown-menu{
	position: relative;
	float: none;
}
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li .left-menu-dropdown a:hover{
	padding:15px 30px;
	transition:all .4s ease 0s;
	background:#fff;
}
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li.open .left-menu-dropdown{
	opacity:1;
	left: 0px;
	z-index:9999;
}
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li.open .left-menu-dropdown.chart-left-menu-std{
	top:0px;
}
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li.open .left-menu-dropdown.form-left-menu-std{
	top: 0px;
}
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li.open .left-menu-dropdown.apps-left-menu-std{
	top: 0px;
}
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li.open .left-menu-dropdown.pages-left-menu-std{
	top: 0px;
}
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li a{
	display: block;
    padding: 15px 20px;
    color: #444;
    transition: all .4s ease 0s;
    font-size: 16px;
}
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li a:hover, .left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li a:focus, .left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li a:active, .left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li a:visited{
	background:#ebebeb;
	color:#444;
}
.left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li .indicator-right-menu{
	float: right;
	font-size:12px;
}
#sidebar.active .left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li.open .left-menu-dropdown {
    left: 80px;
}
#sidebar.active .left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li .mini-dn {
    display:none;
}
#sidebar.active .left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li .big-icon {
    font-size:20px;
}
#sidebar.active .left-custom-menu-adp-wrap ul.left-sidebar-menu-pro li > a {
    padding: 20px 20px;
}
.menu-switcher-pro {
	transition:all .4s ease 0s;
}
.menu-switcher-pro i{
	font-size:20px;
	color:#fff;
}
.mini-navbar .menu-switcher-pro {
    transition: all 0.3s;
}
.menu-switcher-pro button{
	background: none;
	border:none;
}
.menu-switcher-pro button:hover, .menu-switcher-pro button:focus, .menu-switcher-pro button:active, .menu-switcher-pro button:visited{
	background: none;
	border:none;
}
.footer-copyright-area {
    background: #1b2a47;
    padding: 20px 0px;
    text-align: center;
}
.footer-copy-right p {
    margin: 0px;
    font-size: 15px;
    color: #fff;
}
.footer-copy-right a{
	color:#fff;
	text-decoration:none;
}
/*----------------------------------------*/
/*  8. Scroll Area
/*----------------------------------------*/
.comment-scrollbar, .timeline-scrollbar, .messages-scrollbar, .project-list-scrollbar {
    height: 100vh;
}
.mCSB_outside+.mCSB_scrollTools {
    right: 0px;
    margin: 0px 0px;
}
.admintab-wrap.menu-setting-wrap.menu-setting-wrap-bg .mCSB_outside+.mCSB_scrollTools {
    right: -15px;
}
/*----------------------------------------*/
/*  2.  Header top Customize CSS
/*----------------------------------------*/
.header-top-menu .navbar-nav>li>a{
	padding: 20px 20px;
	color: #fff;
	font-size:14px;
}
.header-top-menu .navbar-nav>li a .angle-down-topmenu{
	margin-left:4px;
}
.header-top-menu .navbar-nav>li>a:hover{
	color: #03a9f4;
}
.header-top-menu .navbar-nav>li .dropdown-menu a{
	padding: 10px 20px;
	display:block;
	color: #fff;
}
.header-top-menu .navbar-nav>li .dropdown-menu a:hover{
	background:#2b2a2a;
}
.header-top-menu .navbar-nav>li .dropdown-menu{
	border: 0px solid #ccc; 
    border: 0px solid rgba(0,0,0,.15);
	background-color: #303030;	
}
.header-right-info .navbar-nav>li .dropdown-menu{
	border: 0px solid #ccc; 
    border: 0px solid rgba(0,0,0,.15);
}
.header-top-menu .nav>li>a:focus, .header-top-menu .nav>li>a:hover, .header-top-menu .nav>li>a:active {
    text-decoration: none;
    background-color: transparent;
}
.header-top-menu .nav .open>a, .header-top-menu .nav .open>a:focus, .header-top-menu .nav .open>a:hover{
	background-color: transparent;
}
.header-right-info .nav>li>a:focus, .header-right-info .nav>li>a:hover, .header-right-info .nav>li>a:active {
    text-decoration: none;
    background-color: transparent;
}
.header-right-info .nav.custon-set-tab>li>a {
	color:#fff;
}
.menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a {
	color:#fff;
}
.header-right-info .nav .open>a, .header-top-menu .nav .open>a:focus, .header-top-menu .nav .open>a:hover{
	background-color: transparent;
}
.header-right-info .navbar-nav{
	float:right;
	padding:19px 0px;
}
/*----------------------------------------*/
/*  3.  Header top Menu CSS
/*----------------------------------------*/
.admin-logo{
	padding:10px 0px;
}
.header-top-menu ul.header-top-nav li{
	display:inline-block;
	position:relative;
}
.header-top-menu ul.header-top-nav li ul.dropdown-header-top{
	position:absolute;
	top:130%;
	left:-10px;
	width:200px;
	background:#303030;
	opacity:0;
	transition: all 0.5s ease 0s;
	z-index:999;
	padding:10px 0px;
}
.header-top-menu ul.header-top-nav li ul.dropdown-header-top.in{
	opacity:1;
	top:100%;
	transition: all 0.5s ease 0s;
}
.admin-project-icon{
	margin-left:5px;
	font-size:10px;
	color:#fff;
}
.header-top-menu ul.header-top-nav li .dropdown-header-top li{
	display:block;
}
.header-top-menu ul.header-top-nav li .dropdown-header-top li a{
	display:block;
	color:#fff;
	padding:15px 20px;
}
.header-top-menu ul.header-top-nav li .dropdown-header-top li a:hover{
	background:#03a9f4;
}
.header-top-menu ul.header-top-nav li a{
	font-size:14px;
	color:#fff;
	text-decoration:none;
	text-transform:capitalize;
	display:block;
	padding:18px 10px;
}
.header-right-info {
	
}
.header-right-info ul.header-right-menu li{
	display:inline-block;
}
.header-right-info ul.header-right-menu li .author-message-top, .header-right-info ul.header-right-menu li .notification-author, .header-right-info ul.header-right-menu li .author-log{
   position:absolute;
   top:160%;
   left:-70px;
   width:330px;
   background:#343434;
   text-align:left;
   opacity:0;
   transition: all 0.5s ease 0s;
   visibility:hidden;
   z-index:999;
}
.header-right-info ul.header-right-menu li .dropdown-header-top.author-log{
   width:200px;
   padding:10px 0px;
}
.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li{
   display:block;
}
.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li a{
   padding:10px 20px;
   display:block;
   color:#fff;
   font-size:14px;
}
.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li .author-log-ic{
   margin-right:10px;
}
.header-right-info ul.header-right-menu li .dropdown-header-top.author-log li a:hover, .header-right-info ul.header-right-menu li .dropdown-header-top.author-log li a:focus{
   background:#2b2a2a;
}
.header-right-info ul.header-right-menu li .author-message-top{
   left:-133px;
}
.header-right-info ul.header-right-menu li .notification-author{
   left:-134px;
}
.header-right-info ul.header-right-menu li .author-log{
   left:-2px;
}
.header-right-info ul.header-right-menu li.open .author-message-top, .header-right-info ul.header-right-menu li.open .notification-author, .header-right-info ul.header-right-menu li.open .author-log{
   opacity:1;
   top:165%;
   transition: all 0.5s ease 0s;
   visibility:visible;
}
.header-right-info ul.header-right-menu li ul.message-menu li a, .header-right-info ul.header-right-menu li ul.notification-menu li a {
	margin: 20px 20px;
	display:block;
	text-decoration:none;
	color:#fff;
}
.header-right-info ul.header-right-menu li .message-view a, .header-right-info ul.header-right-menu li .notification-view a{
	display:block;
	color: #fff;
	font-size: 14px;
	border-top:1px solid #f1f1f1;
	padding: 15px 0px;
	text-align:center;
	text-decoration:none;
}
.header-right-info ul.header-right-menu > li > a{
	display:inline-block;
	color:#fff;
	padding: 0px 0px 0px 20px;
	font-size:20px;
	text-decoration:none;
	position:relative;
}
.indicator-nt{
	position:absolute;
	height:5px;
	width:5px;
	background:#03a9f4;
	border-radius:50%;
	top: -5px;
    right: 0px;
}
.indicator-ms{
	position:absolute;
	height:5px;
	width:5px;
	background:#03a9f4;
	border-radius:50%;
	top: -5px;
    right: -7px;
}
.header-right-info .admin-name{
	display: inline-block;
    color: #fff;
    font-size: 16px;
    position: relative;
    top: -4px;
	margin-left:5px;
}
.header-right-info .author-project-icon{
    color: #fff;
    font-size: 10px;
    position: relative;
    top: -4px;
	margin-left:5px;
}
.header-right-info .message-author{
   position:relative;
}
.header-right-info .author-message-top li a{
   color:#fff;
}
.message-single-top h1, .notification-single-top h1{
	font-size: 18px;
    color: #fff;
    font-weight: 300;
    text-align: center;
    padding: 15px 0px;
    margin: 0px;
	border-bottom:1px solid #383838;
}
ul.message-menu, ul.notification-menu {
    height: 325px;
}
ul.message-menu li .message-img{
	float:left;
	width:70px;
	margin-right:10px;
}
.breadcome-heading {
    margin: 14px 0px;
}
ul.notification-menu li .notification-icon{
	float: left;
    width: 50px;
    height: 50px;
    background: #152036;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    border-radius: 50%;
    margin: 5px 10px 5px 0px;
}

ul.message-menu li .message-content, ul.notification-menu li .notification-content{
	position:relative;
}
ul.message-menu li .message-content .message-date, ul.notification-menu li .notification-content .notification-date{
	position:absolute;
	top:0px;
	right:0px;
	font-size:13px;
	font-style: italic;
}
ul.message-menu li .message-content h2, ul.notification-menu li .notification-content h2{
	font-size:16px;
	font-weight:700;
	color:#fff;
}
ul.message-menu li .message-content p, ul.notification-menu li .notification-content p{
	font-size:14px;
	color:#fff;
}
.header-right-info ul.header-right-menu li.open > a{
	color:#fff;
}
.header-top-menu .navbar-nav>li.open > a{
	color:#03a9f4;
}
.header-right-info ul.header-right-menu li .admintab-wrap.menu-setting-wrap.menu-setting-wrap-bg.dropdown-menu {
    background:#343434;
}
.header-right-info ul.header-right-menu li .admintab-wrap.menu-setting-wrap.dropdown-menu {
    position: absolute;
    top: 175%;
    left: -358px;
    width: 400px;
	box-shadow: 0px 2px 3px rgba(0,0,0,.175);
	padding: 20px;
}
.note-heading-indicate {
    margin-top: 20px;
}
.note-heading-indicate h2{
	font-size:20px;
	color:#fff;
}
.note-heading-indicate p{
	font-size:14px;
	color:#fff;
}
.menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a, .menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a:focus, .menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a:hover
.notes-img{
	background:#303030;
}
.notes-img{
	float:left;
}
.notes-img img{
	border-radius:50%;
	width:60px;
}
.notes-list-flow .notes-content {
    margin-left: 80px;
}
.notes-list-flow .notes-content p{
    margin:0px;
	font-size:14px;
	color:#fff;
	line-height:22px;
}
.notes-list-flow .notes-content span{
	font-size:13px;
	color:#fff;
}
.notes-list-area ul.notes-menu-list li {
    margin: 10px 0px;
}
.notes-menu-scrollbar, .project-st-menu-scrollbar{
	height:315px;
}
.project-st-list-area ul.projects-st-menu-list li a{
	color:#303030;
}
.project-st-list-area ul.projects-st-menu-list li {
    padding: 10px 0px;
}
.projects-st-heading{
	position:relative;
}
.projects-st-heading .project-st-time{
	position:absolute;
	top:0px;
	right:0px;
	font-size:13px;
	color:#fff;
}
.projects-st-heading h2{
	font-size:16px;
	color:#fff;
}
.projects-st-heading p{
	font-size:14px;
	color:#fff;
	line-height:22px;
	margin:0px 0px 10px;
}
.projects-st-content p{
	font-size:14px;
	color:#fff;
	margin:0px 0px 5px;
}
.projects-st-content .progress{
	height: 5px;
	margin-bottom: 5px;
}
.projects-st-content.project-rating-cl .progress-bar{
	background-color: #f8ac59;
}
.projects-st-content.project-rating-cl2 .progress-bar{
	background-color: #03a9f4;
}
.project-list-flow, .notes-list-flow {
    margin-right: 15px;
}
ul.setting-panel-list li{
	display: block !important;
}
.checkbox-title-pro h2 {
    display: block;
    font-size: 14px;
    margin: 0px;
    padding: 8px 0px;
	color: #fff;
}
.checkbox-title-pro{
	position:relative;
}
.ts-custom-check{
	position:absolute;
	right:0px;
	top:5px;
}
.onoffswitch {
    position: relative;
    width: 54px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #24caa1;
    border-radius: 3px;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s;
    -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before {
    content: "ON";
    padding-left: 7px;
    background-color: #24caa1;
    color: #FFFFFF;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 7px;
    background-color: #FFFFFF;
    color: #919191;
    text-align: right;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 16px;
    line-height: 16px;
    font-size: 10px;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.onoffswitch-switch {
    display: block;
    width: 18px;
    margin: 0;
    background: #FFFFFF;
    border: 2px solid #24caa1;
    border-radius: 3px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 36px;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0;
}
.header-top-menu .navbar-nav>li .dropdown-menu, .header-right-info ul.header-right-menu li .author-message-top, .header-right-info ul.header-right-menu li .notification-author, .header-right-info ul.header-right-menu li .author-log, .header-right-info ul.header-right-menu li .admintab-wrap.menu-setting-wrap.menu-setting-wrap-bg.dropdown-menu {
    background: #1b2a47;
}
.header-top-menu .navbar-nav>li .dropdown-menu a:hover, .header-right-info ul.header-right-menu li .dropdown-header-top.author-log li a:hover, .header-right-info ul.header-right-menu li .dropdown-header-top.author-log li a:focus, .header-drl-controller-btn.btn-info:active:focus, .btn-info:active:hover {
    background: #152036;
	color:#fff;
	outline:none;
}
.menu-switcher-pro #sidebarCollapse:hover, .menu-switcher-pro #sidebarCollapse:focus, .menu-switcher-pro #sidebarCollapse:active, .menu-switcher-pro #sidebarCollapse:visited{
	outline:none;
}
.header-right-info ul.header-right-menu li .message-view a, .header-right-info ul.header-right-menu li .notification-view a {
    border-top: 1px solid #152036;
}
.message-single-top h1, .notification-single-top h1 {
    border-bottom: 1px solid #152036;
}
.header-top-menu .navbar-nav>li.open > a {
    color: #fff;
}
.header-top-menu .navbar-nav>li>a:hover {
    color: #fff;
}
.menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a, .menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a:focus, .menu-setting-wrap.menu-setting-wrap-bg .nav-tabs>li.active>a:hover
 .notes-img {
    background: #152036;
}
.breadcome-list {
    padding:20px;
	background:#1b2a47;
	margin:100px 0px 40px;
}
.breadcome-heading .form-control, .breadcome-heading .form-control:focus {
    border: 1px solid #152036;
    font-size: 13px;
    height: 34px;
    color: #303030;
    padding-left: 20px;
    padding-right: 40px;
    background: #152036;
    box-shadow: none;
    border-radius: 30px;
    width: 200px;
	transition:all .4s ease 0s;
}
.breadcome-heading .form-control:focus, .breadcome-heading .form-control:active {
    border: 1px solid #24caa1;
}
.header-top-menu .breadcome-heading .form-control {width: 100%;}
.header-top-menu .breadcome-heading a {
    top: 48%;
    left: auto;
    right: 25px;
}
.nalika-angle-dw {
    font-size: 14px;
    position: relative;
    top: -1px;
}
.form-control {
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    color: inherit;
    display: block;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
}
.breadcome-heading a {
    position: absolute;
    top: 50%;
    left: 178px;
    display: block;
    height: 34px;
    line-height: 34px;
    width: 34px;
    text-align: center;
    color: #999;
    margin-top: -17px;
}
ul.breadcome-menu {
    padding-top: 8px;
}
ul.breadcome-menu {
    text-align: right;
}
ul.breadcome-menu li {
    font-size: 14px;
    display: inline-block;
	color:#fff;
}
ul.breadcome-menu li a{
    color:#fff;
}
ul.breadcome-menu li a:hover{
    color:#f1f1f1;
}
.breadcomb-wp {
    display: flex;
}
.breadcomb-ctn {
    margin-left: 20px;
}
.breadcomb-icon i {
    font-size: 20px;
    color: #fff;
    padding: 15px;
    position: relative;
    border-radius: 5px;
    border: 1px solid #fff;
    display: block;
}
.breadcomb-ctn h2{
	font-size:20px;
	color:#fff;
}
.breadcomb-ctn p{
	font-size:14px;
	color:#fff;
	margin:0px;
}
.breadcomb-report {
    text-align: right;
	margin: 5px 0px;
}
.breadcomb-report button{
	background:#152036;
}
.breadcomb-report button:active, .breadcomb-report button:hover, .breadcomb-report button:focus, .breadcomb-report button:visited{
	outline:none;
}
.breadcomb-report i{
	color:#fff;
	font-size:20px;
}
.tooltip-inner{
	padding:5px 15px;
	background:#152036;
}
.tooltip.right .tooltip-arrow{
    border-right-color: #152036;
}
.tooltip.bottom .tooltip-arrow{
	border-bottom-color: #152036;
}
.tooltip.top .tooltip-arrow{
	border-top-color: #152036;
}
.tooltip.left .tooltip-arrow{
	border-left-color: #152036;
}
.analysis-progrebar-ctn .progress {
    height: 5px;
    margin-top: 5px;
    margin-bottom: 0px;
}
.bg-green {
    background: #24caa1;
}
.bg-red {
    background: #eb4b4b;
}
.bg-blue {
    background: #2eb7f3;
}
.bg-purple {
    background: #805bbe;
}
.analysis-progrebar-ctn{
	padding:20px;
	background:#1b2a47;
}
.product-sales-chart, .analytics-info-cs, .tranffic-als-inner, .analytics-rounded, .single-new-trend, .personal-info-wrap, .author-widgets-single, .calender-inner, .product-status-wrap, .review-tab-pro-inner, .income-dashone-total, .analytics-nalika-wrap, .analytics-sparkle-line, .analysis-progrebar, .sparkline8-list, .sparkline9-list, .sparkline7-list, .sparkline10-list, .sparkline12-list, .sparkline13-list, .sparkline14-list, .sparkline13-list, .sparkline11-list, .x-editable-list, .code-editor-single, .blog-details-inner, .charts-single-pro, .about-sparkline, .sparkline-list, .button-ad-wrap, .tab-content-details, .sparkline16-list, .sparkline15-list, .tinymce-single{
	padding:20px;
	background:#1b2a47;
}
.graph-rp{
	text-align:right;
}
.graph-rp i{
	font-size:14px;
}
.sales-sts-ctn {
    font-size: 20px;
}
ul.cus-product-sl-rp li i{
	margin-right:5px;
}
ul.cus-product-sl-rp li h5{
	color:#fff;
}
.pro-sl-hd span{
	font-size:20px;
	color:#fff;
	text-transform: capitalize;
} 
.cus-gh-hd-pro h2{
	font-size:20px;
	color:#fff;
}
.analysis-progrebar-ctn h4, .tranffic-als-inner h3{
	font-size:20px;
	color:#fff;
	text-transform: capitalize;
}
ul.two-part-sp li{
	display:inline-block;
	width: 49%;
	color:#fff;
}
.analytics-info-cs h3{
	font-size:20px;
	color:#fff;
	text-transform: capitalize;
}
.stats-row {
    margin-bottom: 20px;
}
.stat-item {
    display: inline-block;
    padding-right: 15px;
}
.single-new-trend {
    position: relative;
    transition: all 0.3s ease 0s;
}
.single-new-trend > a, .product-tabs a, .widget-image > a, .single-brand > a, .blog-img > a {
    display: block;
}
.single-new-trend img, .single-new-trend img {
    width: 100%;
}
.featured-product-list .overlay-content h2 {
    font-size: 18px;
    left: 20px;
}
.overlay-content h2 {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    left: 20px;
    position: absolute;
    top: 20px;
    transition: all 0.3s ease 0s;
}
.overlay-content a.btn-small {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 14px;
    text-transform: capitalize;
	font-weight:400;
}
.btn-small {
    background: #152036;
    color: #fff !important;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 10px;
    position: absolute;
    right: 10px;
    text-align: center;
    text-transform: uppercase;
    top: 10px;
	border-radius:2px;
}
.actions.graph-rp .btn.active, .actions.graph-rp .btn:active {
    box-shadow: none;
}
.actions.graph-rp .btn-group label {
    color:#fff;
}
.featured-product-list .overlay-content .product-action {
    bottom: 45px;
}
.overlay-content .product-action {
    bottom: 70px;
    left: 50%;
    position: absolute;
    margin-left: -43px;
}
.product-action > ul, #checkout-review-table thead, .single-brand {
    text-align: center;
}
.product-action li, .all-adress-info, .product-attributes > span, .left-content span, .shop-tab-pill ul li, .team-member > a, .footer-left > a {
    display: inline-block;
}
.featured-product-list .product-action a {
    height: 28px;
    line-height: 26px;
    width: 28px;
}
.product-action a {
    border: 1px solid #ddd;
    display: block;
    height: 35px;
    line-height: 35px;
    text-align: center;
    width: 35px;
	color:#fff;
}
.featured-product-list .overlay-content h4 {
    left: 20px;
}
.overlay-content h4 {
    bottom: 20px;
    color: #fff;
    font-size: 20px !important;
    font-weight: 700;
    left: 20px;
    margin: 0;
    position: absolute;
    transition: all 0.3s ease 0s;
	font-size:15px;
}
.pro-rating {
    bottom: 16px;
    position: absolute;
    right: 20px;
	color:#fff;
}
.widget-head-info-box {
    width: 100%;
    height: 240px;
    color: #fff;
    background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    background-size: 400% 400%;
    -webkit-animation: Gradient 15s ease infinite;
    -moz-animation: Gradient 15s ease infinite;
    animation: Gradient 15s ease infinite;
}
.personal-info-ano .widget-head-info-box {
    background: #2eb7f3;
}
.widget-head-info-box{
	padding:20px;
	text-align: center;
}
.stat-item h6{
	font-size:14px;
	margin-bottom:5px;
	color:#fff;
}
.stat-item {
    color: #fff;
}
.analytics-rounded-content h2, .persoanl-widget-hd h2, .fc-center h2{
	font-size:20px;
	color:#fff;
}
.widget-text-box p{
	font-size:14px;
	line-height:24px;
	color:#fff;
}
.persoanl-widget-hd.persoanl1-widget-hd p{
	color:#fff;
}
.social-widget-result.social-widget1-result span{
	color:#fff;
}
.widget-text-box h4{
    color:#fff;
	font-size:20px;
}
.widget-text-box {
    margin-top: 20px;
}
.social-widget-result{
	margin-top: 18px;
}
.social-widget-result span{
	font-size:14px;
	color:#fff;
}
.persoanl-widget-hd p {
    font-size: 14px;
    color: #fff;
}
.like-love-list a{
	color:#fff;
	font-size:14px;
}
.like-love-list a:hover{
	color:#fff;
}
.like-love-list a.btn-primary{
	background:#152036;
	border:1px solid #152036;
}
.like-love-list a.btn-primary:focus, .like-love-list a.btn-primary:active{
	background:#152036;
}
.author-wiget-inner{
	text-align:center;
	padding:20px;
	background:#152036;
}
.fc-content .fc-title {
    color: #fff;
    padding: 5px 5px;
    display: inline-block;
    font-size: 14px;
}
.fc-content .fc-time {
    color: #fff;
    font-size: 14px;
}
.fc button, .fc-button-group, .fc-time-grid .fc-event .fc-time span{
	display:block;
}
.header-top-area {
    position: fixed;
    background: #1b2a47;
    z-index: 99;
    left: 200px;
    right: 0;
    top: 0;
    margin: 0 auto;
    transition: all 0.3s;
}
.jqstooltip {
  width: auto !important;
  height: auto !important;
}
.mini-navbar .header-top-area{
	left: 80px;
}
.add-product a {
    float: right;
    position: absolute;
    top: 20px;
    right: 35px;
    background: #152036;
    padding: 6px 20px;
    color: #fff;
    border-radius: 4px;
}
.breadcome-list.single-page-breadcome{
	padding-top: 30px;
    padding-bottom: 30px;
}
.product-status-wrap h4 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
}
.product-status-wrap table {
    width: 100%;
}
.product-status-wrap table th {
    padding: 20px 5px;
    vertical-align: top;
    border-top: 1px solid #152036;
}
.product-status-wrap table th {
    vertical-align: bottom;
    /* border-bottom: 2px solid #152036; */
	color:#fff;
}
.product-status-wrap th, .product-status-wrap td {
    padding: 10px;
    text-align: left;
    font-size: 14px;
}
.product-status-wrap table td {
    padding: 9px 7px;
    border-top: 1px solid #152036;
	color:#fff;
	font-size:14px;
}
.product-status-wrap table tr {
    padding: 9px 7px;
    border-top: 1px solid #152036;
	color:#fff;
	font-size:14px;
}
.product-status-wrap img {
    width: 60px;
}
.custom-pagination ul.pagination li a{
	color: #fff;
	background-color: #152036;
    border: 1px solid #152036;
}
.custom-pagination ul.pagination{
	margin-bottom: 0px;
}
.product-status-wrap .pd-setting {
    border: none;
    color: #fff;
    padding: 5px 15px;
    font-size: 15px;
    background: #24caa1;
    border-radius: 3px;
}
.product-status-wrap .ps-setting {
    border: none;
    color: #fff;
    padding: 5px 15px;
    font-size: 15px;
    background: #2eb7f3;
    border-radius: 3px;
}
.product-status-wrap .ds-setting {
    border: none;
    color: #fff;
    padding: 5px 15px;
    font-size: 15px;
    background: #eb4b4b;
    border-radius: 3px;
}
.sales-report-stn-his button.product-btn-history, button.pd-setting-ed {
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,.12);
    background: #152036;
}
button.pd-setting-ed {
    display: inline-block;
    margin-right: 5px;
}
.mg-b-pro-edt {
    margin-bottom: 15px;
}
ul.tab-custon-design li, ul.tab-review-design li {
    display: inline-block;
}
#myTab3.tab-review-design li a {
    padding-right: 40px;
    text-transform: capitalize;
	position:relative;
	transition:all .4s ease 0s;
}
#myTab3.tab-review-design li a:before, #myTab4.tab-review-design li a:before {
    position: absolute;
    bottom: -15px;
    left: 0px;
    width: 0%;
    height: 2px;
    background: #fff;
    content: "";
	transition:all .4s ease 0s;
}
#myTab3.tab-review-design li.active a:before, #myTab4.tab-review-design li.active a:before {
    position: absolute;
    bottom: -15px;
    left: 0px;
    width: 80%;
    height: 2px;
    background: #fff;
    content: "";
	transition:all .4s ease 0s;
}
ul.tab-custon-design li.active a, ul.tab-review-design li.active a {
    color: #fff !important;
}
ul.tab-custon-design li a, ul.tab-custon-design li a:hover, ul.tab-custon-design li a:focus, ul.tab-review-design li a, ul.tab-review-design li a:hover, ul.tab-review-design li a:focus {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: #909090;
}
ul.tab-custon-design, ul.tab-review-design {
   padding-bottom: 20px;
    width: 100%;
    background: #152036;
	margin-bottom:20px;
}
.custom-product-edit{
	margin-top:20px;
}
.product-edt-pix-wrap {
    padding: 40px 0px;
}
.pro-edt-img img{
	width:60%;
}
.product-edt-remove button {
    margin-top: 20px;
	background-color: #e12503;
    border-color: #e12503;
}
/****** Style Star Rating Widget *****/
.review-title span {
    font-size: 14px;
    padding-top: 5px;
    display: block;
}
.review-rating {
    text-align:left;
	float:left;
}
.rating {
  border: none;
}
.rating > input {
  display: none;
}
.rating > label:before {
  margin: 5px;
  font-size: 16px;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}
.rating > .half:before {
  content: "\f089";
  position: absolute;
}
.rating > label {
  color: #ddd;
  float: right;
}
.rating > input:checked ~ label, .rating:not(:checked) > label:hover, .rating:not(:checked) > label:hover ~ label {
  color: #24caa1;
} 
.rating > input:checked + label:hover, .rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, .rating > input:checked ~ label:hover ~ label {
  color: #24caa1;
}
.text-muted p{
	font-size:14px;
	margin:0;
}
.txt-primary p{
	font-size: 16px;
    font-weight: 700;
    margin: 0;
}
.custom-pro-edt-ds .btn-primary {
    background: #e12503;
	border-color: #e12503;
}
.custom-pro-edt-ds .btn-warning {
    background: #ccc;
    border-color: #ccc;
}
/*-----------------------
4. single product tab CSS
-------------------------*/
#single-product-tab .active img{
	border:1px solid #152036;
}
ul#single-product-tab{
	margin-top:10px;
	margin-left:-5px;
	margin-right:-5px;
}
ul#single-product-tab li{
	width:25%;
	float:left;
	padding:0px 5px;
	border:1px solid transparent;
}
.single-pro-list {
  margin-top: 20px;
}
.single-product-details h1{
	font-size:20px;
	color:#fff !important;
}
.single-product-details h1, .single-pro-size h6, .color-quality-details h5, .color-quality h4, .single-social-area h3, .sb-shop-product-single h2{
	font-weight:700;
	color:#333;
	text-transform:uppercase;
}
.single-pro-star, .list-star{
	color: #e12503;
	font-size:14px;
}
.single-pro-star i{
	margin:0px 1px;
	color:#fff;
}
.single-pro-price{
	margin:20px 0px;
}
.single-regular, .list-regular{
	color:#fff;
	font-weight:700;
	font-size:24px;
}
.single-old, .list-old{
	color:#fff;
	font-weight:400;
	font-size:14px;
	margin:0px 10px;
}
.single-pro-cn h3{
	font-size:14px;
	color:#fff;
	font-weight:400;
	text-transform:uppercase;
}
.single-pro-cn p, .list-pro-cn p{
	font-size:14px;
	color:#fff;
	font-weight:400;
	line-height: 24px;
}
.single-pro-size h6, .color-quality-details h5, .color-quality h4, .single-social-area h3{
	font-size:14px;
	color:#fff;
}
.single-pro-size{
	margin:20px 0px;
}
.single-pro-size span{
	    color: #333;
    font-size: 12px;
    background: #fff;
    border: 1px solid rgba(0,0,0,.12);
}
.single-product-pr{
	background:#1b2a47;
	padding: 20px;
}
.single-tb-pr {
    background: #1b2a47;
    padding: 20px;
}
.color-quality-details{
	float:left;
}
.color-quality-details .red, .sidebar-color-area .red{
	background:#00ffff;
}
.color-quality-details .green, .sidebar-color-area .green{
	background:#ef7676;
}
.color-quality-details .yellow, .sidebar-color-area .yellow{
	background:#f06eaa;
	border: 1px solid #05c2f9;
}
.color-quality-details .black, .sidebar-color-area .black{
	background:#2094ed;
}
.color-quality-details .white, .sidebar-color-area .white{
	background:#f7941d;
}
.single-pro-size span, .color-quality-details span {
  display: inline-block; 
  font-weight: 300;
  height: 25px;
  line-height: 25px;
  text-align: center;
  width: 25px;
  margin-right:10px;
}
.single-arrow-area{
	height:25px;
	width:25px;
	border: 1px solid #ececec;
}
.color-quality {
  float: left;
  margin: 0px 90px;
}
.pro-quantity-changer a {
  border: 1px solid #ececec;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  text-align: center;
  width: 25px;
  cursor:pointer;
  color:#333;
  text-decoration:none;
}
.pro-quantity-changer [type="text"] {
  border: 1px solid #ececec;
  height: 25px;
  text-align: center;
  width: 50px;
  color:#333;
  font-weight:400;
  font-size:12px;
}
.color-quality-pro{
	margin:20px 0px;
}
.single-social-area a{
	border:1px solid #979797;
	color:#909090;
}
.single-social-area a:hover{
	color:#fff;
}
.pro-button, .pro-viwer{
	float:left;
	margin:10px 0px;
}
.pro-button, .list-pro-button{
	height: 40px;
  line-height: 40px;
  text-align: center;
  width: 150px;
  background: #152036;
  border-radius: 25px;
  transition: all .4s ease 0s;
}
.pro-button a, .pro-button a:focus, .list-pro-button a, .list-pro-button a:focus{
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}
.pro-viwer{
	margin:10px 10px;
}
.pro-viwer a, .list-pro-viwer a {
     border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
    margin-right: 5px;
}
.review-content-section{
	margin-top: 0px;
    margin-left: 15px;
    margin-right: 15px;
}
.custom-pro-edt-ds .btn-ctl-bt, .product-edt-remove .btn-ctl-bt, .review-pro-edt .btn-ctl-bt{
	background:#152036;
	color:#fff;
	border:none;
	outline:none;
	padding: 10px 20px;
}
.form-radio label{
	color:#fff;
}
.review-content-section .input-group input[type="text"]{
	
}
.review-content-section .input-group input::-webkit-input-placeholder, .product-delivary .form-group input::-webkit-input-placeholder, .form-group input::-webkit-input-placeholder, .review-content-section input::-webkit-input-placeholder {
    color:#fff;
}
.review-content-section .input-group input::-moz-placeholder, .product-delivary .form-group input::-moz-placeholder, .form-group input::-moz-placeholder , .review-content-section input::-moz-placeholder {
    color:#fff;
}
.review-content-section .input-group input::-ms-placeholder, .product-delivary .form-group input::-ms-placeholder, .form-group input::-ms-placeholder , .review-content-section input::-ms-placeholder {
    color:#fff;
}
.review-content-section .input-group input::placeholder, .product-delivary .form-group input::placeholder, .form-group input::placeholder, .review-content-section input::placeholder {
    color:#fff;
}
.form-control.pro-edt-select.form-control-primary {
    color: #fff;
}
.review-content-section p{
	color:#fff;
	font-weight:400;
	font-size:14px;
	line-height:24px;
}
.suggested-heading{
	width:33%;
}
.single-social-area a:hover {
    background: #152036;
    border: 1px solid #152036;
}
.single-social-area a {
    border-radius: 50%;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
    margin: 0px 2px;
}
.single-social-area {
    margin-top: 20px;
}
ul.tab-custon-design{
	margin: 0 auto;
}
ul.tab-custon-design, ul.tab-review-design{
  padding: 15px 35px;
  width: 100%;
  background:#152036;
}
ul.tab-custon-design li, ul.tab-review-design li{
	display:inline-block;
}
ul.tab-custon-design li span, ul.tab-review-design li span{
	margin:0px 20px;
	color:#fff;
	font-size:14px;
}
.review-content-section .pro-b-0 {
    margin-bottom: 0px;
}
.review-pro-edt.mg-b-0-pr{
	margin-bottom:0px;
}
ul.tab-custon-design li a, ul.tab-custon-design li a:hover, ul.tab-custon-design li a:focus, ul.tab-review-design li a, ul.tab-review-design li a:hover, ul.tab-review-design li a:focus{
	font-size:18px;
	font-weight:700;
	text-transform:uppercase;
	text-decoration:none;
	color:#909090;
}
ul.tab-custon-design li.active a, ul.tab-review-design li.active a{
	color:#fff !important;
}
.hpanel .panel-body {
    background: #fff;
    border-radius: 2px;
    padding: 15px;
    position: relative;
	-webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.panel-body {
    padding: 15px;
	background:#fff;
}
.m-t-xl {
    margin-top: 40px;
}
.m-t-xl h1{
    font-size:18px;
}
.stats-title h4{
	font-size:20px;
}
.m-t-xl small, .content-bg-pro small{
	font-size:14px;
	line-height:24px;
	color:#444;
}
.stats-icon i, .icon-box i{
	font-size:30px;
}
.content-box h2{
	font-size:20px;
	color:#444;
}
.content-box p{
	font-size:14px;
	line-height:24px;
}
.text-info {
    color: #3498db;
}
.text-warning {
    color: #ffb606;
}
.text-danger {
    color: #e74c3c;
}
.content-box .mg-t-box{
	margin-top:15px;
}
.content-bg-pro h3{
	font-size:20px;
}
.content-bg-pro .text-big{
	font-size:18px;
}
.hpanel.hbggreen .panel-body {
    background: #62cb31;
    color: #fff;
}
.hpanel.hbgblue .panel-body {
    background: #3498db;
    color: #fff;
}
.hpanel.hbgyellow .panel-body {
    background: #ffb606;
    color: #fff;
}
.hpanel.hbgred .panel-body {
    background: #e74c3c;
    color: #fff;
}
.content-bg-pro small{
	color:#fff;
}
#myTab3.tab-review-design{
	padding: 15px 15px;
	background:#1b2a47;
}
#myTab3.tab-review-design li a{
	padding-right: 40px;
	text-transform:capitalize;
}
#myTab3.tab-review-design li i{
	margin-right:5px;
}
.custom-product-edit{
	background:#1b2a47;
}
.input-group-addon {
    background-color: #e12503;
    color: #fff;
}
.mg-b-pro-edt{
	margin-bottom:15px;
}
.form-control.pro-edt-select{
	width:100%;
}
.custom-pro-edt-ds button, .product-edt-remove button{
	padding:5px 15px;
	margin-right:10px;
}
.custom-pro-edt-ds .btn-primary{
	background:#e12503;
}
.custom-pro-edt-ds .btn-warning{
	background:#ccc;
	border-color: #ccc;
}
.form-radio input {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
}
.radio label:hover .helper {
    color: #4099ff;
}
.radio .helper {
    position: absolute;
    top: -0.15rem;
    left: -0.25rem;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #999;
}
.radio .helper::after, .radio .helper::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    margin: 0.25rem;
    width: 1rem;
    height: 1rem;
    -webkit-transition: -webkit-transform 0.28s ease;
    transition: -webkit-transform 0.28s ease;
    transition: transform 0.28s ease,-webkit-transform 0.28s ease;
    border-radius: 50%;
    border: 0.125rem solid #24caa1;
}
.radio.radiofill .helper::after {
    background-color: #24caa1;
}
.radio .helper::after {
    -webkit-transform: scale(0);
    transform: scale(0);
    background-color: #24caa1;
    border-color: #24caa1;
}
.radio.radiofill input:checked~.helper::after {
    -webkit-transform: scale(1);
    transform: scale(1);
}
.radio input:checked~.helper::after {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
}
.product-edt-remove button{
	margin-top:20px;
}
.product-edt-pix-wrap {
    padding: 40px 0px;
}
.pro-edt-img {
    margin-bottom: 20px;
}
.pro-edt-img.mg-b-0{
	margin-bottom: 0px;
}

/*
    Wizard
*/
.product-cart-inner {
    background: #1b2a47;
    padding: 20px 20px;
}
.product-status-wrap.border-pdt-ct p{
	font-size:14px;
	color:#fff;
}
.wizard > .steps{
    position: relative;
    display: block;
    width: 100%;
}
.wizard.vertical > .steps{
    display: inline;
    float: left;
    width: 30%;
}
.wizard > .steps .number{
    font-size: 1.429em;
}
.wizard > .steps > ul > li{
    width: 25%;
}
.wizard > .steps > ul > li,.wizard > .actions > ul > li{
    float: left;
}
.wizard.vertical > .steps > ul > li{
    float: none;
    width: 100%;
}
.wizard > .steps a,.wizard > .steps a:hover,.wizard > .steps a:active{
    display: block;
    width: auto;
    margin: 0 0.5em 0.5em;
    padding: 1em 1em;
    text-decoration: none;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.wizard > .steps .disabled a,.wizard > .steps .disabled a:hover,.wizard > .steps .disabled a:active{
    background: #eee;
    color: #aaa;
    cursor: default;
}
.wizard > .steps .current a,.wizard > .steps .current a:hover,.wizard > .steps .current a:active{
    background: #fff !important ;
    color: #333;
    cursor: default;
}
.wizard > .steps .done a,.wizard > .steps .done a:hover,.wizard > .steps .done a:active{
    background: #9dc8e2;
    color: #fff;
}
.wizard > .steps .error a,.wizard > .steps .error a:hover,.wizard > .steps .error a:active{
    background: #ff3111;
    color: #fff;
}
.wizard > .content{
    display: block;
    margin: 20px 0px;
    min-height: 35em;
    overflow: hidden;
    position: relative;
    width: auto;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.wizard.vertical > .content{
    display: inline;
    float: left;
    margin: 0 2.5% 0.5em 2.5%;
    width: 65%;
}
.wizard > .content > .body{
    float: left;
    position: absolute;
    width: 95%;
    height: 95%;
}
.wizard > .content > .body ul{
    list-style: disc !important;
}
.wizard > .content > .body ul > li{
    display: list-item;
}
.wizard > .content > .body > iframe{
    border: 0 none;
    width: 100%;
    height: 100%;
}
.wizard > .content > .body input{
    display: block;
    border: 1px solid #ccc;
	color:#fff;
}
.wizard > .content > .body input[type="checkbox"]{
    display: inline-block;
}
.wizard > .content > .body input.error{
    background: rgb(251, 227, 228);
    border: 1px solid #fbc2c4;
    color: #8a1f11;
}
.wizard > .content > .body label{
    display: inline-block;
    margin-bottom: 0.5em;
	font-size:14px;
	color:#fff;
}
.wizard > .content > .body label.error{
    color: #8a1f11;
    display: inline-block;
    margin-left: 1.5em;
}
.wizard > .actions{
    position: relative;
    display: block;
    text-align: right;
    width: 100%;
}
.wizard.vertical > .actions{
    display: inline;
    float: right;
    margin: 0 2.5%;
    width: 95%;
}
.wizard > .actions > ul{
    display: inline-block;
    text-align: right;
}
.wizard > .actions > ul > li{
    margin: 0 0.5em;
}
.wizard.vertical > .actions > ul > li{
    margin: 0 0 0 1em;
}
.wizard > .actions a,.wizard > .actions a:hover,.wizard > .actions a:active{
    background: #2184be;
    color: #fff;
    display: block;
    padding: 0.5em 1em;
    text-decoration: none;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.wizard > .actions .disabled a,.wizard > .actions .disabled a:hover,.wizard > .actions .disabled a:active{
    background: #eee;
    color: #333;
}
.current-info{
	display:none;
}
#example-basic-h-1, #example-basic-h-0, #example-basic-h-2, #example-basic-h-3{
	display:none;
}
.product-cart-inner .wizard > .steps a, .product-cart-inner .wizard > .steps a:hover, .product-cart-inner .wizard > .steps a:active{
	padding:10px 15px;
	line-height: 24px;
	font-size:14px;
	background:#152036;
	font-weight:700;
}
.product-cart-inner .wizard > .steps .disabled a, .product-cart-inner .wizard > .steps .disabled a:hover, .product-cart-inner .wizard > .steps .disabled a:active{
	background: #152036;
    color: #fff;
}
.product-cart-inner .wizard > .content > .body{
	width:100%;
	background: #1b2a47;
}
.product-cart-inner .wizard > .content > .body input {
    width: 35px;
}
.wizard > .actions a, .wizard > .actions a:hover, .wizard > .actions a:active {
    background: #152036;
}
.product-list-cart .product-status-wrap{
	border: 0px solid rgba(0,0,0,.12);
	padding:0px;
}
.product-list-cart .product-status-wrap table th{
	border: 0px solid rgba(0,0,0,.12);
}
.product-list-cart h3{
	font-size:16px;
}
.product-delivary input, .payment-details input{
	width:100% !important;
}
.payment-details button, .product-confarmation button{
	background: #fff;
	border-color:#fff;
	color:#333;
}
.payment-details button:hover, .product-confarmation button:hover{
	background: #fff;
	border-color:#fff;
	color:#333;
}
.product-confarmation h2{
	font-size:20px;
	color:#fff;
}
.product-confarmation p{
	font-size:14px;
	color:#fff;
}
.product-confarmation {
    text-align: center;
}
.product-confarmation p{
    padding: 0px 130px;
    line-height: 24px;
    font-size: 14px;
}
#myTab4.tab-review-design li a {
    padding-right: 40px;
    text-transform: capitalize;
	position:relative;
	transition:all .4s ease 0s;
}
.payment-card {
    border: 1px solid rgba(0,0,0,.12);
    padding: 20px;
	background:#fff;
}
.payment-inner-pro i{
	font-size:40px;
	float:right;
}
.payment-inner-pro h5{
	font-size:20px;
	margin-bottom:30px;
}
.credit-card-custom a, .waves-light{
	background:#e12503;
	border-color: #e12503;
}
.payment-method-ht i {
    font-size: 50px;
    margin-right: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
	color:#fff;
}
.payment-adress{
	text-align:center;
}
.payment-adress button{
	background:#e12503;
	border-color:#e12503;
}
.product-cart-dn {
    display: none;
}
.product-status-wrap.border-pdt-ct{
	box-shadow:none;
}
.product-payment-inner-st{
	padding:20px;
	background:#1b2a47;
}
.payment-inner-pro i{
	color:#24caa1;
}
.income-rate-total h3 {
    color: #fff;
    font-size: 25px;
}
.income-rate-total {
    position: relative;
}
.price-graph {
    position: absolute;
    top: 0;
    right: 0;
}
.income-range p {
    font-size: 14px;
    color: #fff;
    margin: 0;
    float: left;
}
.income-range .income-percentange {
    font-size: 14px;
    color: #fff;
    float: right;
    padding: 0px 8px;
    border-radius: 3px;
}


/*----------------------------------------*/
/*  37.  Basic Form css
/*----------------------------------------*/
.sparkline8-graph .basic-login-inner, .sparkline8-graph .login-social-inner, .sparkline9-graph .basic-login-form-ad, .sparkline11-graph .basic-login-form-ad{
	text-align:left;
}
.form-group-inner {
    margin-bottom: 15px;
}
.form-group-inner label{
	font-size:14px;
	color:#303030;
}
.form-group-inner input[type="email"], .form-group-inner input[type="password"], .form-group-inner input[type="text"], .form-select-list .custom-select-value{
	font-size:14px;
	color:#303030;
	border:1px solid #ddd;
	outline:none;
	border-radius:0px;
	box-shadow: none;
}
.form-group-inner input[type="email"]:focus, .form-group-inner input[type="password"]:focus, .form-group-inner input[type="text"]:focus, .form-select-list .custom-select-value:focus{
	border:1px solid #03a9f4;
	outline:none;
	border-radius:0px;
	box-shadow: none;
}
.icheckbox_square-green, .iradio_square-green {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    /* background: url(img/green1.png) no-repeat; */
    border: none;
    cursor: pointer;
}
.icheckbox_square-green {
    background-position: 0 0;
}
.icheckbox_square-green.hover {
    background-position: -22px 0;
}
.icheckbox_square-green.checked {
    background-position: -44px 0;
	transition: all 0.3s ease 0s;
}
.login-btn-inner .check-label{
	margin-left:5px;
}
.login-btn-inner .label-check-inner{
	margin:0px;
	padding-top:5px;
}
.login-btn-inner .login-submit-cs{
	background-color: #03a9f4;
    border-color: #03a9f4;
	font-size:14px;
	font-weight:400;
	border-radius:0px;
}
.login-btn-inner .btn-primary.active.focus, .login-btn-inner .btn-primary.active:focus, .login-btn-inner .btn-primary.active:hover, .login-btn-inner .btn-primary:active.focus, .login-btn-inner .btn-primary:active:focus, .login-btn-inner .btn-primary:active:hover, .login-btn-inner .btn-primary:hover, .login-btn-inner .btn-primary:active, .login-btn-inner .btn-primary:focus{
	background-color: #03a9f0;
    border-color: #03a9f0;
	border-radius:0px;
	outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
}
.create-account-sign a{
	color: #03a9f4;
    font-size: 100px;
    padding: 5px 70px;
    display: block;
}
.create-account-sign a:hover{
	color: #303030;
}
.login-social-inner{
	margin-bottom:20px;
}
.login-social-inner a.button {
    line-height: 42px;
    text-decoration: none;
	margin-right:5px;
}
.login-social-inner .btn-social {
    position: relative;
}
.login-social-inner .span-left {
    padding-left: 55px;
    padding-right: 15px;
}
.login-social-inner .facebook {
    background-color: #3b5998;
}
.login-social-inner .twitter, .login-social-inner .twitter:hover, .login-social-inner .twitter:focus, .login-social-inner .facebook, .login-social-inner .facebook:hover, .login-social-inner .facebook:focus, .login-social-inner .googleplus, .login-social-inner .googleplus:hover, .login-social-inner .googleplus:focus, .login-social-inner .linkedin, .login-social-inner .linkedin:hover, .login-social-inner .linkedin:focus {
    color: #fff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}
.login-social-inner .button {
    border: 0;
    height: 42px;
    color: #fff;
    line-height: 1;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    vertical-align: top;
    display: inline-block;
    -webkit-user-drag: none;
    text-shadow: 0 1px rgba(255, 255, 255, 0.2);
}
.login-social-inner .button{
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    outline: none;
}
.login-social-inner .facebook:hover, .login-social-inner .facebook:focus {
    background-color: #25385F;
}
.login-social-inner .facebook span {
    background-color: #31497D;
}
.login-social-inner .btn-social span {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    -o-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 42px;
    left: 0;
}
.login-social-inner .btn-social i {
    font-size: 22px;
    position: relative;
    top: 2px;
}
.login-social-inner .twitter span {
    background-color: #009AD5;
}
.login-social-inner .twitter {
    background-color: #00acee;
}
.login-social-inner .twitter:hover, .login-social-inner .twitter:focus {
    background-color: #00749F;
}
.login-social-inner .googleplus span {
    background-color: #C03121;
}
.login-social-inner .googleplus:hover, .login-social-inner .googleplus:focus {
    background-color: #8D2418;
}
.login-social-inner .googleplus {
    background-color: #dd4b39;
}
.login-social-inner .linkedin span {
    background-color: #084261;
}
.login-social-inner .linkedin:hover, .login-social-inner .linkedin:focus {
    background-color: #084261;
}
.login-social-inner .linkedin {
    background-color: #0077B5;
}
.login-horizental {
    margin-top: 20px;
}
.form-group-inner label.login2 {
    margin: 0px;
    padding: 7px 0px;
}
.inline-basic-form .form-group-inner{
	margin-bottom:0px;
}
.modal-login-form-inner{
	text-align:left;
}
.modal-nalika-general .modal-body .modal-basic-inner p{
	margin-bottom:15px;
}
.modal-bootstrap.modal-login-form {
    padding: 7px 20px;
	background-color: #03a9f0;
	display:inline-block;
	text-align:center;
}
.modal-bootstrap.modal-login-form a{
	color: #fff;
}
.bt-df-checkbox {
    padding: 8px 0px;
    margin: 0;
}
.bt-df-checkbox .radio-checked {
    margin-right: 8px;
}
.icheckbox_square-green.checked {
    background-position: -44px 0;
}
.icheckbox_square-green.checked.disabled {
    background-position: -88px 0;
}
.iradio_square-green {
    background-position: -109px 0;
}
.iradio_square-green.hover {
    background-position: -131px 0;
}
.iradio_square-green.checked {
    background-position: -153px 0;
	transition: all 0.3s ease 0s;
}
.iradio_square-green.checked.disabled {
    background-position: -197px 0;
}
.inline-checkbox-cs {
    padding: 7px 0px;
}
.inline-checkbox-cs .checkbox-inline{
	padding-left:0px;
}
.form-group-inner.input-with-success label{
	color: #1ab394;
}
.form-group-inner.input-with-success input[type="text"]{
	border: 1px solid #1ab394;
}
.form-group-inner.input-with-warning label{
	color: #f8ac59;
}
.form-group-inner.input-with-warning input[type="text"]{
	border: 1px solid #f8ac59;
}
.form-group-inner.input-with-error label{
	color: #ed5565;
}
.form-group-inner.input-with-error input[type="text"]{
	border: 1px solid #ed5565;
}
.inline-remember-me label {
    margin: 0px;
    padding: 5px 0px;
}
.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}
.input-group-addon:first-child {
    border-right: 0;
}
.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child>.btn, .input-group-btn:first-child>.btn-group>.btn, .input-group-btn:first-child>.dropdown-toggle, .input-group-btn:last-child>.btn-group:not(:last-child)>.btn, .input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group-addon {
    background-color: #152036;
    border: 1px solid #152036;
    border-radius: 1px;
    color: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 6px 12px;
    text-align: center;
	color:#fff;
}
.input-group-addon{
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
}
.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
}
.form-control {
    background-color: #152036;
    background-image: none;
    border: 1px solid #152036;
    border-radius: 1px;
    color: inherit;
    display: block;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
	height:40px;
	color:#fff;
}
.custom-go-button .btn-primary, .custom-dropdowns-button .dropdown-toggle, .dropdown-segmented .dropdown-toggle, .dropdown-segmented .btn-white {
    background-color: #e12503;
    border-color: #e12503;
    color: #FFFFFF;
	border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.custom-go-button .btn-primary, .custom-go-button .btn-primary:active, .custom-go-button .btn-primary:focus, .custom-dropdowns-button .dropdown-toggle, .custom-dropdowns-button .dropdown-toggle:active, .custom-dropdowns-button .dropdown-toggle:focus, .dropdown-segmented .dropdown-toggle, .dropdown-segmented .dropdown-toggle:active, .dropdown-segmented .dropdown-toggle:focus{
	border-top-right-radius: 0;
    border-bottom-right-radius: 0;
	background-color: #e12503;
    border-color: #e12503;
}
.dropdown-segmented .dropdown-toggle, .dropdown-segmented .dropdown-toggle:active, .dropdown-segmented .dropdown-toggle:focus{
	border-left:1px solid #1990c3;
}
.cancel-wp .btn-white, .cancel-wp .btn-white:active, .cancel-wp .btn-white:focus{
	border-radius:0px;
	outline:none;
}
.file-upload-inner.ts-forms input[type="text"]:hover, .file-upload-inner.ts-forms input[type="text"]:focus, .file-upload-inner.ts-forms input[type="text"]:active, .file-upload-inner.ts-forms .file-button:hover + input{
	border: 1px solid #e12503;
	height: 35px;
}
.file-upload-inner.ts-forms input[type="text"]{
	height: 35px;
}
.file-upload-inner.ts-forms .file-button, .file-upload-inner.ts-forms .file-button:active, .file-upload-inner.ts-forms .file-button:focus{
	background: #e12503;
	border-radius:0px;
	width:70px;
	height: 35px;
	line-height: 35px;
	top:0px;
}
.file-upload-inner.ts-forms .prepend-small-btn .file-button, .file-upload-inner.ts-forms .prepend-big-btn .file-button {
    left: 0px;
}
.file-upload-inner.ts-forms .prepend-big-btn input[type="text"] {
    padding-left: 81px;
}
.file-upload-inner.ts-forms .icon-left, .file-upload-inner.ts-forms .icon-right{
	top:0px;
}
.file-upload-inner.file-upload-inner-right.ts-forms .file-button, .file-upload-inner.file-upload-inner-right.ts-forms .file-button:active, .file-upload-inner.file-upload-inner-right.ts-forms .file-button:focus{
	right: 0px !important;
}
.file-upload-inner.file-upload-inner-right.ts-forms .append-small-btn .file-button input, .file-upload-inner.file-upload-inner-right.ts-forms .append-big-btn .file-button input{
	right: 0px !important;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #03a9f4;
}
.chosen-select-single label{
	font-weight:400;
	font-size:14px;
}
.spacer-b16a {
    text-align: left;
    margin-bottom: 5px;
}
.sliderv-wrapper.green-slider.green-left-pro {
    margin-left: 8px;
}
.sliderv-wrapper.black-slider.slider-bl-pro {
    margin-top: 22px;
}
.input-mask-title{
	text-align:right;
	padding: 5px 0px;
}
.input-mark-inner, .data-custon-pick, .touchspin-inner{
	text-align:left;
}
.input-mask-title label, .input-mark-inner .help-block, .data-custon-pick label, .touchspin-inner label{
	font-size:14px;
	font-weight:400;
}
.input-mark-inner .form-control, .data-custon-pick .form-control{
	border-radius:0px;
}
.input-mark-inner .form-control:focus, .input-mark-inner .form-control:active, .data-custon-pick .form-control:focus, .data-custon-pick .form-control:active{
	outline:none;
	box-shadow:none;
	border: 1px solid #03a9f4;
}
.form-group.data-custon-pick.data-custom-mg{
	margin-bottom:0px;
}
.datepicker table tr td.active.active, .datepicker table tr td span.active.active{
	background-color: #03a9f4;
    border-color: #03a9f4;
}
.ts-forms .tsbox {
    position: relative;
}
.ts-forms .label {
    font-size: 14px;
    margin-bottom: 15px;
    height: 14px;
    color: #303030;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 5px 0px;
}
.ts-forms label {
    display: block;
    color: inherit;
    font-weight: normal;
    text-align: left;
    margin-bottom: 0;
}
.ts-forms {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    color: rgba(0,0,0,.54);
    line-height: 1;
    position: relative;
}
.ts-forms input[type="text"], .ts-forms input[type="password"], .ts-forms input[type="email"], .ts-forms input[type="search"], .ts-forms input[type="url"], .ts-forms textarea, .ts-forms select {
	background: #fff;
    border: 1px solid rgba(0,0,0,.12);
    border-radius: 0px;
    color: rgba(0,0,0,.87);
    display: block;
    font-family: inherit;
    font-size: 14px;
    height: 34px;
    padding: 10px 15px;
    width: 100%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all.4s;
    -moz-transition: all.4s;
    -ms-transition: all.4s;
    -o-transition: all.4s;
    transition: all.4s;
}
.sp-replacer {
    background-color: #e0e0e0;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -o-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    border: none;
    bottom: 0;
    cursor: pointer;
    display: block;
    outline: none;
    padding-left: 16px;
    padding-top: 0px;
    position: absolute;
    right: 0;
    top: 0;
    width: 52px;
    color: rgba(0,0,0,.56);
    -webkit-transition: color.4s;
    -moz-transition: color.4s;
    -ms-transition: color.4s;
    -o-transition: color.4s;
    transition: color.4s;
}
.sp-preview-inner, .sp-alpha-inner, .sp-thumb-inner {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.sp-replacer {
	top: 20px !important;
    padding-left: 10px !important;
}
.sp-preview {
    position: relative;
    width: 25px;
    height: 20px;
    border: solid 1px #222;
    margin-right: 5px;
    float: left;
    z-index: 0;
    top: 5px;
}
.sp-dd {
    padding: 2px 0;
    height: 16px;
    line-height: 16px;
    float: left;
    font-size: 10px;
    margin-top: 5px;
}
.dual-list-box-inner option{
	padding:0px 15px;
}
.img-preview-custom{
	height: 130px;
    width: 200px;
	overflow: hidden;
}
.dual-list-box-inner .comon-method{
	margin-top:15px;
}
.images-cropper-pro .btn-primary{
	background-color: #03a9f4;
    border-color: #03a9f4;
}
.images-cropper-pro .btn-primary:active:focus, .images-cropper-pro .btn-primary:active:hover{
	background-color: #03a9f4;
    border-color: #03a9f4;
}
.images-action-pro .btn-warning{
	background-color: #03a9f4;
    border-color: #03a9f4;
}
.images-action-pro .btn-warning:active, .images-action-pro .btn-warning:focus, .images-action-pro .btn-warning:hover{
	background-color: #03a9f4;
    border-color: #03a9f4;
}
.inbox-email-menu-list .nav-tabs>li>a{
	border: 0px solid transparent;
}
.x-editable-list .table{
	margin-bottom:0px;
}
.menu-list-mg-b.menu-list-wrap{
	margin-bottom:40px
}
.inbox-bt-mg{
    margin-bottom:15px;
}
.mg-b-23 {
    margin-bottom: 23px;
}
.mg-b-20, .mg-b-23 {
    margin-bottom: 20px;
}
/*----------------------------------------*/
/*  23.  Analytics css
/*----------------------------------------*/
.analytics-nalika-wrap{
	overflow:hidden;
}
.analytics-nalika{
	background:#1b2a47;
	overflow:hidden;
}
.analytics-nalika .skill{
	overflow:hidden;
}
.analytics-nalika .progress{
	margin-bottom: 0px;
}
.analytics-nalika2 .skill .progress-bar{
	background: #EB3B60 !important;
}
.analytics-nalika3 .skill .progress-bar{
	background: #2BCDC0 !important;
}
.analytics-nalika4 .skill .progress-bar{
	background: #f8ac59 !important;
}
.analytics-sparkle-line .analytics-content h5, .analytics-rounded .analytics-rounded-content h5, .analysis-progrebar .analysis-progrebar-content h5{
	font-size:14px;
	color:#fff;
	text-transform:capitalize;
}
.analytics-sparkle-line .analytics-content h2, .analytics-rounded .analytics-rounded-content h2, .analysis-progrebar .analysis-progrebar-content h2, .analytics-nalika .skill .progress .lead-content h3{
	font-size:20px;
	color:#fff;
}
.analytics-nalika .skill .progress .lead-content{
	top:-55px;
}
.analysis-progrebar-content .small p{
	font-size:14px;
	color:#fff;
	margin:0px;
}
.analysis-progrebar-content .progress{
	height: 5px;
	margin-bottom:10px;
}
.skill-content-3 {
    overflow: hidden
}
.skill .progress .lead-content {
    left: 0;
    position: absolute;
    top: -50px;
    z-index: 9;
    width: 100%;
}
.skill .progress .lead-content h3{
    font-size: 20px;
    margin: 0px 0px;
}
.skill .progress .lead-content p{
    font-size: 14px;
    margin: 5px 0px;
	color:#fff;
}
.skill .progress {
    background-color: #f0f0f0;
    border-radius: 0;
    box-shadow: none;
    height: 5px;
    overflow: visible;
    position: relative;
	margin: 60px 0px;
	margin-bottom:0px;
}
.skill .progress.progress-bt {
    margin-bottom:0px;
}
.skill .progress-bar > span {
    background: #333 none repeat scroll 0 0;
    float: right;
    font-size: 11px;
    margin-right: 10px;
    margin-top: -26px;
    position: relative;
    padding: 0 5px;
	z-index:9;
}
.skill .progress-bar > span:before,
.skill .progress-bar > span:after {
    border: medium solid transparent;
    content: " ";
    height: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    width: 0;
}
.skill .progress-bar > span:before {
    border-top-color: #333;
    border-width: 5px;
    left: 50%;
    margin-left: -5px;
}
.holax-shop h3,
.we-are-good-at h3 {
    font-size: 18px;
    margin-bottom: 25px;
}
.skill .progress:nth-child(1) .progress-bar {
    background: #23C6EC;
}
.skill .progress:nth-child(2) .progress-bar {
    background: #EB3B60;
}
.skill .progress:nth-child(3) .progress-bar {
    background: #2BCDC0;
}
ul.mailbox-list li.active a, ul.h-list li.active a {
    background: #f7f9fa;
    border: 1px solid #e4e5e7;
    font-weight: 600;
    border-radius: 1px;
}
ul.mailbox-list li a, ul.h-list li a {
   padding: 8px 15px;
    display: block;
    font-size: 14px;
	transition:all .4s ease 0s
}
.mailbox-hd {
    background: #fff;
}
.mailbox-pagination {
    text-align: right;
}
.table.table-mailbox>tbody>tr>td, .table.table-mailbox>tbody>tr>th, .table.table-mailbox>tfoot>tr>td, .table.table-mailbox>tfoot>tr>th, .table.table-mailbox>thead>tr>td, .table.table-mailbox>thead>tr>th {
    padding: 0px;
    vertical-align: middle;
}
.table-mailbox tr.unread td a {
    font-weight: 600;
    font-size: 14px;
}
.panel-body a {
    font-size: 14px;
    color: #444;
    margin-top: 15px;
	transition:all .4s ease 0s
}
.contact-footer, .panel-footer {
    background: #ddd;
}
ul.mailbox-list li:hover a, ul.h-list li:hover a {
    font-weight: 600;
	transition:all .4s ease 0s
}
.hpanel.email-compose {
    background: #fff;
	padding:20px;
}
.hpanel .panel-body.panel-csm{
	border: 1px solid rgba(0,0,0,.12);
}
.mailbox-view .panel-heading {
    padding: 10px 0px;
}
.custom-address-mailbox {
    margin-bottom: 20px;
}
.file-body i.fa {
    font-size: 40px;
}
.incon-ctn-view{
	text-align:center;
}
.btn-success.compose-btn {
    background-color: #e12503;
    border-color: #e12503;
    color: #fff;
}
.panel-body.no-padding{
	box-shadow:none;
}
.panel-body p {
    font-size: 14px;
    color: #444;
    line-height: 22px;
}
.btn-primary.ft-compse {
    background-color: #e12503;
    border-color: #e12503;
    color: #fff;
}
#map, #map1, #map2, #googleMap, #maplan, #map6, #map7, #map8 {
    height: 100%;
}
.google-map-single {
    width: 100%;
    height: 300px;
}
.main-sparkline8-hd h1, .main-sparkline9-hd h1, .main-spark7-hd h1, .main-sparkline10-hd h1, .main-sparkline11-hd h1, .main-sparkline12-hd h1, .main-sparkline13-hd h1, .main-sparkline14-hd h1{
	font-size:20px;
	color:#303030;
}
.basic-choropleth {
    height: 400px;
    width: 100%;
}
.alert-title.dropzone-custom-sys h2, .download-custom h2, .alert-title h2, .code-editor-single h2 {
    font-size: 20px;
    color: #444;
}
.alert-title.dropzone-custom-sys p, .download-custom p, .alert-title p, .sparkline-content p {
    font-size: 14px;
    color: #444;
    line-height: 24px;
}
.preloader-single {
    background: #fff;
    width: 100%;
    height: 350px;
    padding: 15px;
    border: 1px solid rgba(0,0,0,.12);
}
.img-preview-custom {
    height: 130px;
    width: 200px;
    overflow: hidden;
}
.file-cs-ctn {
    text-align: center;
    padding: 30px 0px !important;
}
.custonfile .btn-primary {
    color: #fff;
    background-color: #e12503;
    border-color: #e12503;
}
.dropdown-menu.filedropdown{
	width:100%;
}
.dropdown-menu.filedropdown li a{
	margin-top:0px;
	padding:8px 20px;
}
.dropdown-menu.filedropdown li i{
	margin-right:5px;
}
.custom-panel-jw a{
	font-size:18px;
}
.contact-stat span, .contact-stat strong {
    font-size: 13px;
    color: #444;
}
.project-people img {
    width: 28px;
    height: 28px;
    margin-left: 2px;
    margin-bottom: 4px;
}
.panel-body a {
    font-size: 14px;
    color: #444;
    margin-top: 15px;
}
.project-value h2 {
    font-size: 25px;
    color: #444;
    margin-top: 10px;
}
.custom-project-dsl h2 {
    margin: 0;
    padding: 10px 15px;
    background: #ddd;
    font-size: 16px;
}
.m-t-none {
    font-size: 20px;
    color: #444;
}
.project-dtl-info h1 {
    font-size: 25px;
    color: #444;
}
.blog-box{
	background:#fff;
	-webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.blog-std {
    padding-top: 15px;
}
.blog-std p {
    margin: 2px 0px;
}
.custom-blog-hd{
	background:#fff;
	border: 1px solid rgba(0,0,0,.12);
}
.blog-std p{
	margin:2px 0px;
}
.blog-std{
	padding-top:15px;
}
.blog-pra p{
	margin:0;
}
.blog-img img {
    margin-bottom: 10px;
}
.blog-image {
    position: relative;
}
.blog-image img{
	border: 1px solid rgba(0,0,0,.12);
}
.blog-date {
    background: #e12503 none repeat scroll 0 0;
    border: 4px solid #fff;
    border-radius: 50%;
    bottom: -25px;
    color: #fff;
    height: 70px;
    left: 0;
    line-height: 15px;
    position: absolute;
    text-align: center;
    width: 70px;
}
.blog-date p {
    font-weight: 400;
    margin: 0;
    padding: 7px 10px;
    font-size: 16px;
    display: block;
    line-height: 20px;
}
.blog-date .blog-day {
    font-size: 14px;
}
.details-blog-dt {
    margin-left: 60px;
    margin-bottom: 20px;
}
.details-blog-dt a, .details-blog-dt a:hover, .details-blog-dt a:focus {
    color: #222;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
}
.blog-details a.blog-ht, .blog-details a.blog-ht:hover, .blog-details a.blog-ht:focus {
    font-size: 20px;
    font-weight: 700;
    color: #222;
    text-transform: capitalize;
    text-decoration: none;
}
.blog-details p {
    color: #222;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}
.comment-head h3::before, .lead-head h3:before {
    background: #e7e7e7 none repeat scroll 0 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
}
.comment-head h3::before, .comment-head h3::after {
    width: 37%;
}
.comment-head h3::after, .lead-head h3:after {
    background: #e7e7e7 none repeat scroll 0 0;
    content: "";
    height: 1px;
    right: 0;
    position: absolute;
    top: 50%;
}
.user-comment {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 15px;
}
.user-comment img {
    float: left;
    margin-right: 20px;
    margin-bottom: 10px;
	border-radius: 50%;
}
.comment-head h3 {
    margin: 20px 0;
    text-transform: uppercase;
    position: relative;
    text-align: center;
}
.comment-head h3, .comment-details h4, .lead-head h3 {
    color: #222;
    font-size: 16px;
    font-weight: 700;
}
.blog-sig-details-dt span {
    margin: 10px 80px 0;
    display: inline-block;
}
.comment-details p {
    color: #222;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}
.comment-replay {
    float: right;
    color: #e12503;
    font-size: 14px;
    text-transform: uppercase;
}
.lead-head h3 {
    text-transform: uppercase;
    position: relative;
    text-align: center;
}
.lead-head h3:before, .lead-head h3:after {
    width: 30%;
}
.comment-head h3::before, .lead-head h3:before {
    background: #e7e7e7 none repeat scroll 0 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
}
.lead-head h3:before, .lead-head h3:after {
    width: 30%;
}
.comment-head h3::after, .lead-head h3:after {
    background: #e7e7e7 none repeat scroll 0 0;
    content: "";
    height: 1px;
    right: 0;
    position: absolute;
    top: 50%;
}
.user-comment {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 15px;
}
.admin-comment {
    padding: 20px 0px;
    padding-left: 40px;
}
.user-coment2 {
    border-bottom: none;
    padding: 20px 0px;
}
.coment-area input[type="text"] {
    height: 40px;
}
.coment-area input[type="text"], .coment-area textarea {
    width: 100%;
    border: 1px solid #e7e7e7;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    padding: 0px 10px;
    outline: none;
}
.coment-area input[type="text"]:focus, .coment-area textarea:focus {
    border: 1px solid #e12503;
}
.coment-area input[type="submit"] {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    padding: 10px 30px;
    background: #e12503;
    border: none;
    border-radius: 30px;
    transition: all .4s ease 0s;
}
.coment-area textarea {
    padding-top: 10px;
}
.coment-area, .coment-area textarea {
    margin: 20px 0px;
}
.back-backend a {
    background: #e12503;
	border-color:#e12503;
}
.back-link {
    float: left;
    width: 100%;
    margin: 10px;
}
.content-error h1 {
    font-size: 40px;
    color: #444;
}
.content-error .counter {
    color: #e12503;
}
.content-error {
    text-align: center;
    margin: 0px 60px;
}
.content-error p {
    font-size: 14px;
    color: #444;
    line-height: 24px;
}
.content-error a {
    display: inline-block;
    margin: 0px 5px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    padding: 10px 30px;
    border-radius: 30px;
    background: #e12503;
    transition: all .4s ease 0s;
}
.login-footer {
    margin-top: 15px;
}
.main-spark-hd h1, .smart-main-spark-hd h1{
	font-size:20px;
}
.custom-datatable-overright table tbody tr td a {
    color: #444;
    font-size: 14px;
}
.custom-datatable-overright table tbody tr td {
    padding-left: 10px !important;
    padding-right: 5px !important;
    font-size: 14px;
    text-align: left;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background-color: #e12503;
    border-color: #e12503;
}
.fixed-table-pagination {
    padding: 0px 10px;
}
.tab-content-details h2 {
    font-size: 20px;
    color: #303030;
}
.tab-content-details {
    text-align: center;
    background: #fff;
    padding: 20px 100px;
}
.tab-content-details p {
    font-size: 14px;
    color: #303030;
    line-height: 24px;
}
.color-line {
    background: #f7f9fa;
    height: 6px;
    background-image: -webkit-linear-gradient(left,#34495e,#34495e 25%,#9b59b6 25%,#9b59b6 35%,#3498db 35%,#3498db 45%,#62cb31 45%,#62cb31 55%,#ffb606 55%,#ffb606 65%,#e67e22 65%,#e67e22 75%,#e74c3c 85%,#e74c3c 85%,#c0392b 85%,#c0392b 100%);
    background-image: -moz-linear-gradient(left,#34495e,#34495e 25%,#9b59b6 25%,#9b59b6 35%,#3498db 35%,#3498db 45%,#62cb31 45%,#62cb31 55%,#ffb606 55%,#ffb606 65%,#e67e22 65%,#e67e22 75%,#e74c3c 85%,#e74c3c 85%,#c0392b 85%,#c0392b 100%);
    background-image: -ms-linear-gradient(left,#34495e,#34495e 25%,#9b59b6 25%,#9b59b6 35%,#3498db 35%,#3498db 45%,#62cb31 45%,#62cb31 55%,#ffb606 55%,#ffb606 65%,#e67e22 65%,#e67e22 75%,#e74c3c 85%,#e74c3c 85%,#c0392b 85%,#c0392b 100%);
    background-image: linear-gradient(to right,#34495e,#34495e 25%,#9b59b6 25%,#9b59b6 35%,#3498db 35%,#3498db 45%,#62cb31 45%,#62cb31 55%,#ffb606 55%,#ffb606 65%,#e67e22 65%,#e67e22 75%,#e74c3c 85%,#e74c3c 85%,#c0392b 85%,#c0392b 100%);
    background-size: 100% 6px;
    background-position: 50% 100%;
    background-repeat: no-repeat;
}
.form-group-inner {
    margin-bottom: 15px;
}
.form-group-inner label{
	font-size:14px;
	color:#303030;
}
.form-group-inner input[type="email"], .form-group-inner input[type="password"], .form-group-inner input[type="text"], .form-select-list .custom-select-value{
	font-size:14px;
	color:#303030;
	border:1px solid #ddd;
	outline:none;
	border-radius:0px;
	box-shadow: none;
}
.form-group-inner input[type="email"]:focus, .form-group-inner input[type="password"]:focus, .form-group-inner input[type="text"]:focus, .form-select-list .custom-select-value:focus{
	border:1px solid #e12503;
	outline:none;
	border-radius:0px;
	box-shadow: none;
}
.icheckbox_square-green, .iradio_square-green {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    /* background: url(img/green1.png) no-repeat; */
    border: none;
    cursor: pointer;
}
.icheckbox_square-green {
    background-position: 0 0;
}
.icheckbox_square-green.hover {
    background-position: -22px 0;
}
.icheckbox_square-green.checked {
    background-position: -44px 0;
	transition: all 0.3s ease 0s;
}
.login-btn-inner .check-label{
	margin-left:5px;
}
.login-btn-inner .label-check-inner{
	margin:0px;
	padding-top:5px;
}
.login-btn-inner .login-submit-cs{
	background-color: #e12503;
    border-color: #e12503;
	font-size:14px;
	font-weight:400;
	border-radius:0px;
}
.modal-bootstrap.modal-login-form {
    padding: 7px 20px;
    background-color: #e12503;
    display: inline-block;
    text-align: center;
}
.modal-bootstrap.modal-login-form a{
    color:#fff;
}
.login-btn-inner .btn-primary.active.focus, .login-btn-inner .btn-primary.active:focus, .login-btn-inner .btn-primary.active:hover, .login-btn-inner .btn-primary:active.focus, .login-btn-inner .btn-primary:active:focus, .login-btn-inner .btn-primary:active:hover, .login-btn-inner .btn-primary:hover, .login-btn-inner .btn-primary:active, .login-btn-inner .btn-primary:focus{
	background-color: #303030;
    border-color: #303030;
	border-radius:0px;
	outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
}
.create-account-sign a{
	color: #e12503;
    font-size: 100px;
    padding: 5px 70px;
    display: block;
}
.create-account-sign a:hover{
	color: #303030;
}
.login-social-inner{
	margin-bottom:20px;
}
.login-social-inner a.button {
    line-height: 42px;
    text-decoration: none;
	margin-right:5px;
}
.login-social-inner .btn-social {
    position: relative;
}
.login-social-inner .span-left {
    padding-left: 55px;
    padding-right: 15px;
}
.login-social-inner .facebook {
    background-color: #3b5998;
}
.login-social-inner .twitter, .login-social-inner .twitter:hover, .login-social-inner .twitter:focus, .login-social-inner .facebook, .login-social-inner .facebook:hover, .login-social-inner .facebook:focus, .login-social-inner .googleplus, .login-social-inner .googleplus:hover, .login-social-inner .googleplus:focus, .login-social-inner .linkedin, .login-social-inner .linkedin:hover, .login-social-inner .linkedin:focus {
    color: #fff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}
.login-social-inner .button {
    border: 0;
    height: 42px;
    color: #fff;
    line-height: 1;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    vertical-align: top;
    display: inline-block;
    -webkit-user-drag: none;
    text-shadow: 0 1px rgba(255, 255, 255, 0.2);
}
.login-social-inner .button{
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    outline: none;
}
.login-social-inner .facebook:hover, .login-social-inner .facebook:focus {
    background-color: #25385F;
}
.login-social-inner .facebook span {
    background-color: #31497D;
}
.login-social-inner .btn-social span {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    -o-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 42px;
    left: 0;
}
.login-social-inner .btn-social i {
    font-size: 22px;
    position: relative;
    top: 2px;
}
.login-social-inner .twitter span {
    background-color: #009AD5;
}
.login-social-inner .twitter {
    background-color: #00acee;
}
.login-social-inner .twitter:hover, .login-social-inner .twitter:focus {
    background-color: #00749F;
}
.login-social-inner .googleplus span {
    background-color: #C03121;
}
.login-social-inner .googleplus:hover, .login-social-inner .googleplus:focus {
    background-color: #8D2418;
}
.login-social-inner .googleplus {
    background-color: #dd4b39;
}
.login-social-inner .linkedin span {
    background-color: #084261;
}
.login-social-inner .linkedin:hover, .login-social-inner .linkedin:focus {
    background-color: #084261;
}
.login-social-inner .linkedin {
    background-color: #0077B5;
}
.form-group-inner label.login2 {
    margin: 0px;
    padding: 7px 0px;
}
.bt-df-checkbox {
    padding: 8px 0px;
    margin: 0;
}
.bt-df-checkbox .radio-checked {
    margin-right: 8px;
}
.icheckbox_square-green.checked {
    background-position: -44px 0;
}
.icheckbox_square-green.checked.disabled {
    background-position: -88px 0;
}
.iradio_square-green {
    background-position: -109px 0;
}
.iradio_square-green.hover {
    background-position: -131px 0;
}
.iradio_square-green.checked {
    background-position: -153px 0;
	transition: all 0.3s ease 0s;
}
.iradio_square-green.checked.disabled {
    background-position: -197px 0;
}
.inline-checkbox-cs {
    padding: 7px 0px;
}
.inline-checkbox-cs .checkbox-inline{
	padding-left:0px;
}
.checkbox.login-checkbox label {
    padding-left: 0px;
}
.btn-success.loginbtn {
    background-color: #e12503;
    border-color: #e12503;
}
.lock-inner i {
    font-size: 60px;
    color: #e12503;
}
.lock-inner .text-success {
    color: #e12503;
}
.lock-inner .btn-primary {
    background: #e12503;
	border-color:#e12503;
}
.poss-recover .btn-success {
    background-color: #e12503;
    border-color: #e12503;
}
.main-sparkline16-hd h1, .main-sparkline15-hd h1{
	font-size:20px;
}
.knob-single input {
    position: absolute;
    top: 0px;
    right: 25px;
}
.download-custom i {
    font-size: 40px;
}
.dropzone-custom-sys {
    text-align: center;
    padding: 30px 100px;
    background: #fff;
}
div#sparklinehome canvas {
    width: 100% !important;
}
.fc hr, .fc tbody, .fc td, .fc th, .fc thead, .fc-row {
    border-color: #1b2a47!important;
    background: #1b2a47!important;
}
a.fc-day-number {
    color: #fff;
}
button.fc-next-button.fc-button.fc-state-default.fc-corner-right, button.fc-prev-button.fc-button.fc-state-default.fc-corner-left, button.fc-today-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right.fc-state-disabled, button.fc-month-button.fc-button.fc-state-default.fc-corner-left.fc-state-active, button.fc-agendaWeek-button.fc-button.fc-state-default, button.fc-agendaDay-button.fc-button.fc-state-default, button.fc-listWeek-button.fc-button.fc-state-default.fc-corner-right {
    background: #152036;
    box-shadow: none;
    color: #fff;
    border: 0px solid #152036;
}
.fc-center {
    color: #fff;
}
th.fc-day-header.fc-widget-header {
    color: #fff;
}
.actions.graph-rp a {
    color: #fff;
}
.persoanl-widget-hd.persoanl1-widget-hd {
    margin-top: 20px;
}
.flot-chart {
    height: 319px;
}
.flot-chart.flot-chart-sts{
	height: 308px;
}
.flot-chart-sts {
    width: calc(100% + 16px);
    left: -10px;
    bottom: -10px;
}
#flotTip, .flot-tooltip {
    position: absolute;
    color: #333;
    display: none;
    font-size: 12px;
    box-shadow: 2px 2px 5px rgba(0,0,0,.1);
    padding: 3px 10px;
    background-color: #fff;
    z-index: 99999;
}
.jqstooltip {
  width: auto !important;
  height: auto !important;
}
.portlet-title {
    margin-bottom: 20px;
}
.flot-chart-sts.flot-chart.curved-chart-statistic{
	height: 432px;
}
.flot-chart.flot-chart-sts.line-chart-statistic{
	height: 362px;
}
.last-month-sc{
	font-size:14px;
}
.last-month-sc.cl-one{
	color:#24caa1;
}
.last-month-sc.cl-two{
	color:#13dafe;
}
.last-month-sc.cl-three{
	color:#bd98f9;
}
.fc-list-item-title a {
    color: #fff;
}
.fc hr, .fc tbody, .fc td, .fc th, .fc thead, .fc-row {
    color: #fff;
}
.fc-state-default.fc-corner-left {
    background: #152036;
	color:#fff;
	border: none;
    box-shadow: none;
}
.mini-navbar .profile-social-dtl, .mini-navbar .min-dtn{
	display:none;
}
.mini-navbar .profile-dtl img {
    height: 50px;
}
.fc tbody .fc-row .fc-content-skeleton tbody tr:last-child td {
   padding-bottom: 0px;
}
.left-custom-menu-adp-wrap .mCustomScrollBox, .left-custom-menu-adp-wrap .mCSB_container{
	overflow:unset;
}
.sidebar-nav {text-align: left;}
.mini-navbar #sidebar.active .sidebar-nav ul.metismenu li ul.submenu-angle{
	width: 200px;
    left: 80px;
    position: absolute;
    z-index: 999;
}
.mini-navbar #sidebar.active .sidebar-nav ul.metismenu li ul.submenu-angle li a{
	text-align:left;
}
.mini-navbar #sidebar.active .sidebar-nav .metismenu a {
    padding: 16px 15px;
}
.review-pro-edt.mg-b-0-pt{
	margin-bottom:0px;
}
.review-pro-edt button{
	padding:5px 15px;
	background:#152036;
	border-color: #152036;
}
.review-pro-edt button:hover, .review-pro-edt button:focus, .review-pro-edt button:active{
	background:#152036 !important; 
	border-color: #152036 !important;
	outline:none;
}
.credit-card-custom .bg-btn-cl, .devit-card-custom .bg-btn-cl, .review-content-section .bg-btn-cl{
	background:#152036;
	border-color: #152036;
	color:#fff;
}
.main-logo{
    height: 60px;
    width: 30px;
}
.dashboard{
    margin-top: 200px;
}
.breadcome-area{
    height: 100vh;
    padding-left: 210px;
    background-color:#152036; 

}
/* .product-status-wrap{
   background-color: #03a9f4;
    width: 80%;
    margin-left: 230px;
} */
.customername{
    display: flex;
    align-items: center !important;
    text-align: center !important;
    justify-content: center;
}
.page-item{
    padding: 3px;
    background-color:#152036;
    color: #fff;
}
.page-item a:hover{
    list-style: none !important;
    text-decoration: none !important;
}
.maindiv{
    /* margin-top: -680px; */
    background-color:#152036;
    height: 100vh;
}
.activepagination a{
    background-color: grey !important;
}
/* .maindiv1{
    margin-top: -680px !important;
} */
/* .product-status{
    margin-top: -680px;

} */
.single-product-tab-area{
    margin-top: -700px;
    padding: -900px;
}
.review-tab-pro-inner{
    padding: 200px;
}

label {
    font-size: .7rem;
}

input.form-control, input.form-control:focus {
    background: #F0F0FF;
}

.MedicineMain .col-lg-6{
    /* padding-left: 50px;
    padding-right: 50px;
    background-color: #1b2a47; */
}
.MedicineMain .col-lg-6 label{
    display: block !important;
    float: left;
}
.medicineAddFormIpTag{
    width: 100%;
}
.abc{
    color: red;
}
.LabelName{
    color: white;
    font-size: 15px;
}

/* .test2{
    height: 800px;
    width: 800px;
    background-color: red;
} */
.uploadprs{
    margin-top: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    color: white;
    margin-left: -20px;
  }
  .uploadnow{
    margin: 0 0 0 30px;
    color: #152036;
    font-weight: 500;
    /* background-color: #24aeb1; */
    text-align: right;
  }
  .uploadfile{
    color: #152036 !important;
    font-size: 15px;
    background: #fff;
    height: 40px;
    line-height: 40px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 15px;
    border-radius: 5px;
  }
  .fa fa-search{
    margin-right: 200px;
  }

.deleteModal .btnModalParent {
    display: flex;
}
.deleteModal .btnModalParent button {
    background: #c7c7c7;
    border: none;
    margin-right: 15px;
    padding: 5px 20px;
    border-radius: 5px;
}
.deleteModal .btnModalParent button.btn-delete {
    background: red;
    color: #fff;
}
button.btn-delete-fnt {
    background: red;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-top: 5px;
}
button.btn-delete-fnt i.fa {
    font-size: 13px;
    line-height: 20px;
}

.form-bg {
    background-color: #1b2a47;
    margin-bottom: 50px !important;
    display: inline-block;
    width: 100%;
}
.form-bg .col-lg-4 {
    margin-bottom: 12px;
}
.form-bg .LabelName {
    margin-bottom: 3px;
}
.form-bg input, .form-bg select {
    width: 100%;
    padding-left: 10px;
    height: 40px;
    border-radius: 5px;
    border: none;
}
.form-bg input::placeholder, .form-bg input::placeholder {font-size: 15px;}
.form-bg input[type=submit] {
    width: auto;
    height: auto;
    padding: 12px 60px;
    background: #3cbfe7;
    line-height: 24px;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    transition: ease-in-out 0.5s;
    -webkit-transition: ease-in-out 0.5s;
    -o-transition: ease-in-out 0.5s;
    -ms-transition: ease-in-out 0.5s;
}
.form-bg input[type=submit]:hover {
    background: #fff;
    color: #3cbfe7;
    transition: ease-in-out 0.5s;
    -webkit-transition: ease-in-out 0.5s;
    -o-transition: ease-in-out 0.5s;
    -ms-transition: ease-in-out 0.5s;
}
.submit-small-btn.form-bg input[type=submit] {
    padding: 10px 60px;
    line-height: 20px;
}

.tab-order-list button {
    padding: 4px 20px;
    background: #3cbfe7;
    line-height: 24px;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    transition: ease-in-out 0.5s;
    -webkit-transition: ease-in-out 0.5s;
    -o-transition: ease-in-out 0.5s;
    -ms-transition: ease-in-out 0.5s;
}

.btnModalParent button {
    width: 46%;
    border: none;
    margin: 0 2%;
    padding: 10px;
    border-radius: 10px;
}
.btnModalParent button.btn-accept {
    background: #57b327;
    color: #fff;
}
.btnModalParent button.btn-reject {
    background: red;
    color: #fff;
}

.btnModalParent button {
    width: 46%;
    border: none;
    margin: 0 2%;
    padding: 10px;
    border-radius: 10px;
}
.addAlternateMedicine h6 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 20px;
}
.addAlternateMedicine input {
    width: 70%;
    height: 50px;
    padding: 0 15px;
    border-radius: 5px 0 0 5px;
    border: none;
    float: left;
}
.addAlternateMedicine button {
    width: 30%;
    height: 50px;
    border: none;
    border-radius: 0 5px 5px 0;
    line-height: 18px;
    background: #36a0da;
    color: #fff;
}
.med-listing {
    margin-top: 20px;
    color: #fff;
    width: 100%;
}
.med-listing button {
    width: auto;
    height: 30px;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    float: right;
}
.cust-details {
    padding: 20px;
    background: #1b2a47;
    color: #fff;
    width: 100%;
    margin-bottom: 50px;
}
.orderMedicineDetail {
    color: #fff;
}
.orderMedicineDetail p {
    margin: 0;
}
.orderMedicineDetail button {
    border: none;
    margin: 10px 0 20px;
    padding: 4px 15px;
    border-radius: 10px;
    background: red;
    color: #fff;
}
button.btn-edit-fnt {
    background: #57b327;
    color: #fff;
    border: none;
    border-radius: 20px;
    width: 50px;
    height: 30px;
    margin-top: 5px;
    margin-left: 10px;
}
ul.pagination {
    margin-top: 35px;
    border-top: 1px solid #152036;
    padding-top: 15px;
}
ul.pagination li.page-item {
    padding: 5px 15px;
    border-radius: 5px;
    margin-right: 15px;
    text-transform: capitalize;
    font-size: 14px;
}
.approved-modal.modal {top: 20%;}
.approved-modal.modal .modal-header {display: none !important;}
button.updatedProfileBtn {
    border: none;
    padding: 5px 20px;
    border-radius: 10px;
    margin-top: 15px;
    background: #57b327;
    color: #fff;
}

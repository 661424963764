/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1169px) {
    .left-sidebar-pro{
        display:block;
    }
    .mobile-menu-area{
        display:none;
    }
    .logo-pro{
        display:block;
        text-align:center;
        background:#fff;
    }
    .menu-switcher-pro{
        display:none;
    }
    .logo-pro{
        display:none;
    }
    .col-lg-1.col-md-0.col-sm-1.col-xs-12{
        display:none;
    }
    .mg-stock-res-n{
        display:none;
    }
    .login2.pull-right.pull-right-pro{
        text-align: left !important;
        float: left !important;
    }
    .res-dis-nn {
        display: none !important;
    }
    .header-top-area {
        position: relative;
        left: 0px;
    }
    .analysis-progrebar-ctn {
        margin-top: 0px;
    }
    .breadcome-list {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .res-mg-t-15{
        margin-top:15px;
    }
    }
    
     
    /* Tablet desktop :768px. */
    @media (min-width: 768px) and (max-width: 991px) {
    .left-sidebar-pro{
        display:none;
    }
    .all-content-wrapper {
        margin-left: 0px;
    }
    .mobile-menu-area{
        display:block;
    }
    .logo-pro{
        display:block;
        text-align:center;
        background:#fff;
    }
    .menu-switcher-pro{
        display:none;
    }
    .header-top-menu{
        display:none;
    }
    .header-right-info .navbar-nav {
        float: none;
        text-align:center;
        margin: 0px 230px;
    }
    .responsive-dn-stock{
        display:none;
    }
    .login2.pull-right.pull-right-pro{
        text-align: left !important;
        float: left !important;
    }
    .header-top-area {
        position: relative;
        left: 0px;
    }
    .analysis-progrebar-ctn {
        margin-top: 0px;
    }
    .breadcome-list {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .res-mg-t-15{
        margin-top:15px;
    }
    .res-mg-t-30{
        margin-top:30px;
    }
    
    }
    
     
    /* small mobile :320px. */
    @media (max-width: 767px) {
    .container {width:300px}
    .left-sidebar-pro{
        display:none;
    }
    .all-content-wrapper {
        margin-left: 0px;
    }
    .mobile-menu-area{
        display:block;
    }
    .logo-pro{
        display:block;
        text-align:center;
        background:#fff;
    }
    .menu-switcher-pro{
        display:none;
    }
    .header-top-menu{
        display:none;
    }
    .header-right-info .navbar-nav {
        float: none;
        text-align:center;
    }
    .header-right-info ul.header-right-menu li .author-message-top {
        left: 4px;
        z-index:99999999;
    }
    .header-right-info ul.header-right-menu li .notification-author {
        left: -33px;
        z-index:99999999;
    }
    .header-right-info ul.header-right-menu li .author-log {
       z-index:99999999;
    }
    .header-right-info ul.header-right-menu > li > a {
        padding: 0px 0px 0px 13px;
    }
    .header-right-info ul.header-right-menu li .admintab-wrap.menu-setting-wrap.dropdown-menu {
        left: -223px;
        width: 270px;
        padding: 10px;
        z-index: 99999999;
    }
    .header-right-info ul.header-right-menu li .author-message-top, .header-right-info ul.header-right-menu li .notification-author, .header-right-info ul.header-right-menu li .author-log {
        width: 270px;
    }
    .admintab-wrap.menu-setting-wrap .nav > li > a {
        padding: 10px 10px;
    }
    .welcome-inner{
        text-align:center;
    }
    .product-statas-wrap {
        display: flex;
        float: none;
        text-align:center;
        padding: 10px 0px;
    }
    .responsive-mg-b-30{
        margin-bottom:30px;
    }
    .reso-mg-b-30{
        margin-bottom:30px;
    }
    .responsive-mg-b-0{
        margin-bottom:0px;
    }
    .responsive-mg-b-10{
        margin-bottom:10px;
    }
    .mt-t-30.mg-b-15.responsive-mg-b-30{
        margin-bottom:30px;
    }
    .responsive-mg-t-0{
        margin-top:0px;
    }
    .sale-earning-tl {
        text-align: center;
    }
    .history-anl-icon {
        text-align: center;
    }
    .product-hisory-tln-ht {
        text-align: center;
    }
    .product-status-wrap{
        overflow-y: hidden;
        width: 100%;
    }
    .fc-toolbar .fc-right {
        float: left;
        margin: 15px 0px;
    }
    .tab-content-details {
        padding: 20px 0px;
    }
    .login2.pull-right.pull-right-pro{
        text-align: left !important;
        float: left !important;
    }
    .blog-sig-details-dt span {
        margin: 10px 5px 0;
    }
    .fixed-table-toolbar .columns, .fixed-table-toolbar .search {
        float:left !important;
    }
    .dropzone-custom-sys {
        padding: 30px 0px;
    }
    .pdf-single-pro .media {
        width: 100% !important;
        overflow-x: auto;
        overflow-y: auto;
        height: 100%;
    }
    .peity-res-scroll, .res-tree-ov {
        width: 100% !important;
        overflow-x: auto;
        overflow-y: auto;
        height: 100%;
    }
    .product-list-cart .product-status-wrap{
        width: 100% !important;
        overflow-x: auto;
        overflow-y: auto;
        height: 100%;
    }
    .header-top-area {
        position: relative;
        left: 0px;
    }
    .analysis-progrebar-ctn {
        margin-top: 0px;
    }
    .breadcome-list {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .res-mg-t-15{
        margin-top:15px;
    }
    .res-mg-t-30{
        margin-top:30px;
    }
    #myTab3.tab-review-design li a {
        margin: 12px 0px;
        display: block;
    }
    }
     
    /* Large Mobile :480px. */
    @media only screen and (min-width: 480px) and (max-width: 767px) {
    .container {width:450px}
    .fc-toolbar .fc-right {
        float: right;
        margin: 0px 0px;
    }
    .product-statas-wrap {
        margin: 0px 110px;
    }
    .knob-single input {
        right: 120px;
    }
    }
     
    